import PropTypes from 'prop-types';
import React from 'react';
import { Equipment } from '../../../../../types/tqdb';

import styles from './Requirements.css';

type Requirement = 'strengthRequirement' | 'dexterityRequirement' | 'intelligenceRequirement' | 'levelRequirement';

const requirements: [Requirement, string][] = [
  ['strengthRequirement', 'STR'],
  ['dexterityRequirement', 'DEX'],
  ['intelligenceRequirement', 'INT'],
  ['levelRequirement', 'LVL'],
];

interface Props {
  item: Equipment;
}

const Requirements = ({ item }: Props) => {
  // Skip item that have no requirements
  if (!requirements.some((requirement) => item[`${requirement[0]}`])) {
    return <div className={styles.wrapper} />;
  }

  const renderRequiment = (requirement: [Requirement, string]) =>
    item[requirement[0]] ? (
      <div key={requirement[0]}>
        {`${requirement[1]} `}
        {item[requirement[0]]}
      </div>
    ) : null;

  return (
    <div className={styles.wrapper}>
      Requirements
      <div className={styles.requirements}>{requirements.map(renderRequiment)}</div>
    </div>
  );
};

export default Requirements;
