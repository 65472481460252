import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define languages
export type Language = 'cs' | 'de' | 'en' | 'es' | 'fr' | 'it' | 'ja' | 'ko' | 'pl' | 'ru' | 'uk' | 'zh';
export const languages: Language[] = ['cs', 'de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'pl', 'ru', 'uk', 'zh'];

// Define storage & default language
const STORAGE_KEY = 'tqdbLocale';
const storedLanguage = localStorage.getItem(STORAGE_KEY) as Language;
const defaultLanguage = storedLanguage && languages.includes(storedLanguage) ? storedLanguage : 'en';

// Initialize messages
const context = require.context('../../messages', true, /.*/);
const messages = context.keys().reduce<Record<string, any>>((acc, filename) => {
  acc[filename.match(/\/(\w+)\.ya?ml$/)![1]] = context(filename);
  return acc;
}, {});

interface I18NState {
  messages: Record<string, Record<string, string>>;
  language: Language;
}

const initialState: I18NState = {
  messages,
  language:
    defaultLanguage ||
    navigator.languages.map((lang) => lang.split('-')[0]).find(Reflect.hasOwnProperty.bind(messages)) ||
    navigator.language.split('-')[0] ||
    // Backup default is english
    'en',
};

export const dataSlice = createSlice({
  initialState,
  name: 'data',
  reducers: {
    setLanguage(state, action: PayloadAction<Language>) {
      state.language = action.payload;

      // Store the selected language:
      localStorage.setItem(STORAGE_KEY, action.payload);
    },
  },
});

export const { setLanguage } = dataSlice.actions;
export default dataSlice.reducer;
