import React, { useState } from 'react';

import { Creature } from '../../../types/tqdb';
import { useAppSelector } from '../../store';
import { selectCreatures } from '../../store/data.selectors';
import VirtualCollection from '../VirtualCollection';
import Monster from './Monster';

const Monsters = () => {
  const monsters = useAppSelector(selectCreatures);

  const [difficulty, setDifficulty] = useState<Record<string, number>>({});
  const [tab, setTab] = useState<Record<string, number>>({});

  /**
   * Set the difficulty tab for a monster.
   *
   * This needs to be managed through this component because the
   * react-virtualized library will unmount the <Monster> components
   * when they leave the viewport.
   *
   */
  const handleSetDifficulty = (monster: string, difficulty: number) => {
    setDifficulty((prevDifficulty) => ({
      ...prevDifficulty,
      [monster]: difficulty,
    }));
  };

  /**
   * Set the content tab for a monster.
   *
   * This needs to be managed through this component because the
   * react-virtualized library will unmount the <Monster> components
   * when they leave the viewport.
   *
   */
  const handleSetTab = (tab: string, difficulty: number) => {
    setTab((prevTab) => ({
      ...prevTab,
      [tab]: difficulty,
    }));
  };

  const renderMonster = (monster: Creature) => (
    <Monster
      difficulty={difficulty[monster.tag] || 0}
      monster={monster}
      setDifficulty={handleSetDifficulty}
      setTab={handleSetTab}
      tab={tab[monster.tag] || 0}
    />
  );

  return (
    <VirtualCollection
      itemHeight={425}
      itemWidth={475}
      gutter={16}
      items={monsters}
      renderItem={renderMonster}
      passThroughProps={{
        difficulty,
        tab,
      }}
    />
  );
};

export default Monsters;
