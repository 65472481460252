import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { Language, languages, setLanguage } from '../../store/i18n.slice';
import { loadLocaleMessages } from '../../util';
import usePageTracking from '../../util/analytics';
import App from '../App';

const AppProvider = () => {
  usePageTracking();

  const { language } = useAppSelector((state) => state.i18n);
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const languagePrefix = params.languagePrefix as Language;
    // If this is a new language:
    if (languagePrefix && languages.includes(languagePrefix) && languagePrefix !== language) {
      dispatch(setLanguage(languagePrefix));
    }
  }, []);

  return (
    <IntlProvider textComponent={React.Fragment} locale={language} messages={loadLocaleMessages(language)}>
      <App />
    </IntlProvider>
  );
};

export default AppProvider;
