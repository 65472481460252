import classNames from 'classnames';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from '../../../store';
import messages from './messages';
import styles from './Navigation.css';

const categories = [
  {
    header: 'Armor',
    items: [messages.head, messages.arms, messages.torso, messages.legs],
  },
  {
    header: 'Weapons',
    items: [
      messages.axe,
      messages.spear,
      messages.bow,
      messages.staff,
      messages.club,
      messages.sword,
      messages.shield,
      messages.throwing,
    ],
  },
  {
    header: 'Jewelry',
    items: [messages.ring, messages.amulet],
  },
  {
    header: 'Relics & Charms',
    items: [messages.relic, messages.charm],
  },
  {
    header: "Artifacts, Formula's & Scrolls",
    items: [messages.artifact, messages.formula, messages.scroll],
  },
];

const Navigation = () => {
  const { language} = useAppSelector(state => state.i18n);
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  const closeMenu = () => {
    setOpen(false);
  };

  const toggleMenu = () => {
    setOpen(!open);
  };

  const hamburgerClass = classNames(styles.hamburger, {
    [styles.hamburgerOpen]: open,
  });

  const dropDownClass = classNames(styles.dropdown, {
    [styles.dropdownClosed]: !open,
  });

  return (
    <nav className={styles.navigation}>
      <button id="nav-button" className={hamburgerClass} onClick={toggleMenu} type="button" />
      <div className={dropDownClass}>
        <div>
          <span className={styles.category}>Monsters & Quests</span>
          <div className={styles.categoryItems}>
            <NavLink
              to={`/${language}/monsters`}
              className={({ isActive }) => classNames(styles.categoryItem, { [styles.categoryActive]: isActive })}
              onClick={closeMenu}
            >
              <div className={styles.categoryLabel}>Monsters</div>
            </NavLink>
            <NavLink
              to={`/${language}/quests`}
              className={({ isActive }) => classNames(styles.categoryItem, { [styles.categoryActive]: isActive })}
              onClick={closeMenu}
            >
              <div className={styles.categoryLabel}>Quests</div>
            </NavLink>
          </div>
        </div>
        <div>
          <span className={styles.category}>Affixes</span>
          <div className={styles.categoryItems}>
            <NavLink
              to={`/${language}/affix/prefixes`}
              className={({ isActive }) => classNames(styles.categoryItem, { [styles.categoryActive]: isActive })}
              onClick={closeMenu}
            >
              <div className={styles.categoryLabel}>Prefixes</div>
            </NavLink>
            <NavLink
              to={`/${language}/affix/suffixes`}
              className={({ isActive }) => classNames(styles.categoryItem, { [styles.categoryActive]: isActive })}
              onClick={closeMenu}
            >
              <div className={styles.categoryLabel}>Suffixes</div>
            </NavLink>
          </div>
        </div>
        <div>
          <span className={styles.category}>Sets</span>
          <div className={styles.categoryItems}>
            <NavLink
              to={`/${language}/set`}
              className={({ isActive }) => classNames(styles.categoryItem, { [styles.categoryActive]: isActive })}
              onClick={closeMenu}
            >
              <div className={styles.categoryLabel}>Set List</div>
            </NavLink>
          </div>
        </div>
        {categories.map((category) => (
          <div key={category.header.toLowerCase()}>
            <span className={styles.category}>{category.header}</span>
            <div className={styles.categoryItems}>
              {category.items.map((item) => {
                const link = intl.formatMessage(item).toLowerCase();

                return (
                  <NavLink
                    key={link}
                    to={`/${language}/category/${link}`}
                    className={({ isActive }) => classNames(styles.categoryItem, { [styles.categoryActive]: isActive })}
                    onClick={closeMenu}
                  >
                    <div className={styles.categoryLabel}>
                      <FormattedMessage {...item} />
                    </div>
                  </NavLink>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </nav>
  );
};

export default Navigation;
