import React from 'react';
import { useAppSelector } from '../../store';
import { selectQuests } from '../../store/data.selectors';
import Quest from './Quest';
import styles from './Quests.css';

const Quests = () => {
  const quests = useAppSelector(selectQuests);

  return (
    <div className={styles.wrapper}>
      {quests.map((quest) => (
        <Quest key={quest.tag} quest={quest} />
      ))}
    </div>
  );
};

export default Quests;
