import PropTypes from 'prop-types';
import React from 'react';

import Base from '../Base';
import Properties from '../../Properties';
import styles from './Scroll.css';

const CLEANING_REGEX = /{.*?}/g;

export default class Scroll extends React.Component {
  static propTypes = {
    item: PropTypes.shape().isRequired,
  };

  render() {
    const { item } = this.props;

    const properties = (
      <div>
        <div className={styles.description}>{item.description.replace(CLEANING_REGEX, '')}</div>
        {item.properties && <Properties properties={item.properties} />}
      </div>
    );

    return (
      <Base
        contentClass={styles.content}
        headerClass={styles.header}
        imageClass={styles.image}
        properties={properties}
        {...this.props}
      />
    );
  }
}
