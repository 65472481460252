import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Equipment } from '../../../types/tqdb';
import { useAppSelector } from '../../store';
import { selectCategoryEquipment } from '../../store/data.selectors';
import EquipmentComponent from '../Equipment';
import VirtualCollection from '../VirtualCollection';

const Category = () => {
  const { catId } = useParams();
  const equipment = useAppSelector((state) => selectCategoryEquipment(state, catId!));
  const [tabs, setTabs] = useState<Record<string, number>>({});

  /**
   * Set the content tab for an item.
   *
   * This needs to be managed through this component because the
   * react-virtualized library will unmount the <Monster> components
   * when they leave the viewport.
   *
   */
  const setTab = (item: string, tab: number) => {
    setTabs((prevTabs) => ({
      ...prevTabs,
      [item]: tab,
    }));
  };

  const renderItem = (item: Equipment) => (
    <EquipmentComponent item={item} limitHeight setTab={setTab} tab={tabs[item.link] || 0} />
  );

  return (
    <VirtualCollection
      itemHeight={425}
      itemWidth={344}
      gutter={16}
      items={equipment}
      renderItem={renderItem}
      passThroughProps={{
        category: catId,
        tabs,
      }}
    />
  );
};

export default Category;
