import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import loader from '../../images/loader.svg';
import { useAppDispatch, useAppSelector } from '../../store';
import { loadData } from '../../store/data.thunks';
import Affixes from '../Affixes';
import Category from '../Category';
import EquipmentPage from '../Equipment/Page';
import HomePage from '../Home';
import Footer from '../Layout/Footer';
import SidePanel from '../Layout/SidePanel';
import TopPanel from '../Layout/TopPanel';
import Monsters from '../Monsters';
import Quests from '../Quests';
import SearchPage from '../Search';
import SetDetails from '../Set/Details';
import SetList from '../Set/List';
import NotFound from '../shared/NotFound';
import styles from './App.css';

const App = () => {
  const { language } = useAppSelector((state) => state.i18n);
  const { loading, languageLoaded } = useAppSelector((state) => state.data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Only load if a language change occurred
    if (language !== languageLoaded) {
      dispatch(loadData());
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <SidePanel />
      <div className={styles.right}>
        <TopPanel />
        <div className={styles.contentWrapper}>
          <main className={styles.content}>
            {loading ? (
              <>
                <img className={styles.loader} src={loader} alt="Loading…" />
                <span className={styles.loaderLabel}>Loading…</span>
              </>
            ) : (
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/affix/:affixType" element={<Affixes />} />
                <Route path="/category/:catId" element={<Category />} />
                <Route path="/monsters" element={<Monsters />} />
                <Route path="/quests" element={<Quests />} />
                <Route path="/set" element={<SetList />} />
                <Route path="/set/:setId" element={<SetDetails />} />
                <Route path="/equipment/:itemId" element={<EquipmentPage />} />
                <Route path="/search/:query" element={<SearchPage />} />
                <Route element={<NotFound />} />
              </Routes>
            )}
          </main>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default App;
