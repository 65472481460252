import classNames from 'classnames';
import React from 'react';
import styles from './Image.css';
import sprite from '../../../../css/sprite.css';
import { Equipment } from '../../../../../types/tqdb';

interface Props {
  className?: string;
  item: Equipment;
}

const Image = ({ className, item }: Props) => {
  const wrapperClass = classNames(styles.wrapper, className);
  const imageClass = classNames(styles.image, sprite[item.icon || item.tag]);

  return (
    <div className={wrapperClass}>
      <span className={imageClass} />
    </div>
  );
};

export default Image;
