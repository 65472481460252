import React from 'react';
import { useAppSelector } from '../../../store';
import { selectEquipmentSets } from '../../../store/data.selectors';
import Details from '../Details';
import styles from './List.css';

const List = () => {
  const sets = useAppSelector(selectEquipmentSets);

  return (
    <div>
      <h1>List of sets</h1>
      {sets.map(({ set, setMembers }) => (
        <div className={styles.wrapper} key={set.tag}>
          <Details set={set} setMembers={setMembers} />
        </div>
      ))}
    </div>
  );
};

export default List;
