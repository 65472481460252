import React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../store';
import Properties from '../../Properties';
import Base from '../Base';
import { BaseEquipmentProps } from '../Base/Base';
import styles from './Artifact.css';

const Artifact = (props: BaseEquipmentProps) => {
  const equipment = useAppSelector((state) => state.data.equipment);
  const { language } = useAppSelector((state) => state.i18n);
  const formula = equipment[`formula-${props.item.tag}`];

  // Properties for artifacts:
  const properties = (
    <div>
      <div className={styles.linkWrapper}>
        From{' '}
        <Link className={styles.link} to={`/${language}/equipment/${formula.link}`}>
          {formula.name}
        </Link>
      </div>
      <Properties properties={props.item.properties} />
    </div>
  );

  return (
    <Base
      contentClass={styles.content}
      header={props.item.classification}
      headerClass={styles.header}
      imageClass={styles.image}
      properties={properties}
      {...props}
    />
  );
};

export default Artifact;
