import { Property } from '../../../../types/tqdb';

const suffixMod = 'Modifier';

const augments = [
  'augmentSkillName1',
  'augmentSkillName2',
  'augmentMasteryName1',
  'augmentMasteryName2',
  'augmentAllLevel',
];
const offensive = [
  'Physical',
  'Bleeding',
  'Pierce',
  'Total',
  'Bonus',
  'Poison',
  'Elemental',
  'Fire',
  'Cold',
  'Lightning',
  'Life',
  'TotalDamage',
  'PercentCurrentLife',
  'ManaBurn',
  'SlowLifeLeach',
  'SlowManaLeach',
  'LifeLeech',
  'Disruption',
];

const offensiveEffects = [
  'SlowTotalSpeed',
  'SlowAttackSpeed',
  'SlowCastingSpeed',
  'SlowRunSpeed',
  'SlowOffensiveAbility',
  'SlowDefensiveAbility',
  'SlowOffensiveReduction',
  'SlowDefensiveReduction',
  'TotalDamageReductionPercent',
  'TotalDamageReductionAbsolute',
  'TotalResistanceReductionPercent',
  'TotalResistanceReductionAbsolute',
  'Fumble',
  'ProjectileFumble',
  'Confusion',
  'Convert',
  'Fear',
  'Freeze',
  'Petrify',
  'Stun',
  'Sleep',
  'Taunt',
  'Trap',
];

export default class SortProperties {
  properties: (keyof Property)[] = [];
  sorted: (keyof Property)[] = [];

  checkProperty(property: string | keyof Property) {
    if (this.properties.includes(property as keyof Property)) {
      this.sorted.push(property as keyof Property);
    }
  }

  sort(properties: Property) {
    this.properties = Object.keys(properties) as (keyof Property)[];
    this.sorted = [];

    // Run the sorts:
    this.sortSkillProperties();
    this.sortOffensive();
    this.sortRetaliation();
    this.sortRacial();
    this.sortDefensive();
    this.sortCharacter();
    this.sortAugments();
    this.sortSkillGrants();

    // Append non-sorted properties at the end
    this.sorted = [...this.sorted, ...this.properties.filter((property) => !this.sorted.includes(property))];

    return this.sorted;
  }

  sortAugments() {
    augments.forEach((augment) => {
      this.checkProperty(augment as keyof Property);
    });
  }

  sortCharacter() {
    this.properties.forEach((property) => {
      if (property.startsWith('character')) {
        this.sorted.push(property);
      }
    });
  }

  sortDefensive() {
    this.properties.forEach((property) => {
      if (property.startsWith('defensive')) {
        this.sorted.push(property);
      }
    });
  }

  sortOffensive() {
    // First check global chances
    if (this.properties.includes('offensiveGlobalChance')) {
      // First add the global chance:
      this.sorted.push('offensiveGlobalChance');
    }

    // Run through both prefixes:
    const prefixes = ['offensive', 'offensiveSlow'];

    prefixes.forEach((prefix) => {
      offensive.forEach((property) => {
        this.checkProperty(prefix + property + suffixMod);
      });

      offensive.forEach((property) => {
        this.checkProperty((prefix + property) );
      });
    });

    // Offensive duration effects:
    offensiveEffects.forEach((property) => {
      this.checkProperty(`offensive${property}`);
    });

    // Offensive duration (modifier) effects:
    offensiveEffects.forEach((property) => {
      this.checkProperty(`offensive${property}${suffixMod}`);
    });
  }

  sortRacial() {
    this.properties.forEach((property) => {
      if (property.startsWith('racial')) {
        this.sorted.push(property);
      }
    });
  }

  sortRetaliation() {
    this.properties.forEach((property) => {
      if (property.startsWith('retaliation')) {
        this.sorted.push(property);
      }
    });
  }

  sortSkillProperties() {
    this.properties.forEach((property) => {
      if (
        property.startsWith('skill') ||
        property === 'projectileExplosionRadius' ||
        property === 'projectileFragmentsLaunchNumber'
      ) {
        this.sorted.push(property);
      }
    });
  }

  sortSkillGrants() {
    // One last time for skill grants
    this.properties.forEach((property) => {
      if (property.startsWith('itemSkill')) {
        this.sorted.push(property);
      }
    });
  }
}
