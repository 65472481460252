import PropTypes from 'prop-types';
import React from 'react';

import Base from '../Base';
import Properties from '../../Properties';
import styles from './Jewelry.css';

export default class Jewelry extends React.Component {
  static propTypes = {
    item: PropTypes.shape().isRequired,
  };

  render() {
    const { item } = this.props;

    // The properties for jewelry:
    const properties = <Properties properties={item.properties || {}} />;

    return (
      <Base
        contentClass={styles.content}
        headerClass={styles.header}
        imageClass={styles.image}
        properties={properties}
        {...this.props}
      />
    );
  }
}
