import React from 'react';
import { useAppSelector } from '../../store';
import Armor from './Armor';
import Artifact from './Artifact';
import { BaseEquipmentProps } from './Base/Base';
import Formula from './Formula';
import Jewelry from './Jewelry';
import Relic from './Relic';
import Scroll from './Scroll';
import Weapon from './Weapon';
import Shield from './Weapon/Shield';
import Staff from './Weapon/Staff';

const EQUIPMENT = {
  ArmorProtective_Forearm: Armor,
  ArmorProtective_Head: Armor,
  ArmorProtective_LowerBody: Armor,
  ArmorProtective_UpperBody: Armor,
  ArmorJewelry_Ring: Jewelry,
  ArmorJewelry_Amulet: Jewelry,
  ItemArtifact: Artifact,
  ItemArtifactFormula: Formula,
  ItemCharm: Relic,
  ItemRelic: Relic,
  OneShot_Scroll: Scroll,
  WeaponArmor_Shield: Shield,
  WeaponMelee_Axe: Weapon,
  WeaponHunting_Bow: Weapon,
  WeaponHunting_RangedOneHand: Weapon,
  WeaponHunting_Spear: Weapon,
  WeaponMagical_Staff: Staff,
  WeaponMelee_Mace: Weapon,
  WeaponMelee_Sword: Weapon,
};

const EquipmentBase = (props: BaseEquipmentProps) => {
  if (!props.item) {
    return null;
  }

  const EquipmentComponent = EQUIPMENT[props.item.type];
  return <EquipmentComponent key={props.item.tag} {...props} />;
};

export default EquipmentBase;
