import classNames from 'classnames';
import React from 'react';
import { Equipment } from '../../../../../types/tqdb';
import { getDLCAbbreviation, getDLCName } from '../../../../util';
import styles from './DLCMarker.css';

interface Props {
  item: { tag: string };
  rarity?: string;
}

const DLCMarker = ({ item, rarity }: Props) => {
  if (!item.tag.startsWith('x2') && !item.tag.startsWith('x3') && !item.tag.startsWith('x4')) {
    return null;
  }

  return (
    <div className={styles.wrapper} title={`${getDLCName(item.tag)} DLC`}>
      <div className={classNames(styles.corner, rarity ? styles[rarity] : null)} />
      <div className={styles.label}>{getDLCAbbreviation(item.tag)}</div>
    </div>
  );
};

export default DLCMarker;
