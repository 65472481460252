import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Equipment } from '../../../../../types/tqdb';
import rarityMessages from '../../../Layout/TopPanel/messages';
import messages from './messages';

interface Props {
  item: Equipment;
}

const DropsIn = ({ item }: Props) => {
  const intl = useIntl();

  if (item.classification !== intl.formatMessage(rarityMessages.rare)) {
    return null;
  }

  return (
    <div>
      <FormattedMessage {...messages.difficulty} />
      {': '}
      {item.dropsIn}
    </div>
  );
};

export default DropsIn;
