import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Affix } from '../../../../types/tqdb';
import { typedKeys } from '../../../util';
import DLCMarker from '../../Equipment/Base/DLCMarker';
import Properties from '../../Properties';
import styles from './Affix.css';
import messages from './messages';

const ARMOR = {
  head: 'Head',
  torso: 'Chest',
  arm: 'Arms',
  arms: 'Arms',
  leg: 'Legs',
  legs: 'Legs',
};

const JEWELRY = {
  amulet: 'Amulet',
  ring: 'Ring',
};

const WEAPONS = {
  axe: 'Axe',
  bow: 'Bow',
  club: 'Club',
  shield: 'Shield',
  spear: 'Spear',
  staff: 'Staff',
  sword: 'Sword',
};

interface Props {
  affix: Affix;
}

const AffixComponent = ({ affix }: Props) => {
  // Create the applicable equipment list, starting with armors:
  const appliesTo = affix.equipment.split(',');

  // The armor slots are separately applies for mages/warriors, but it would
  // look better if they were combined, with parentheses indicating which class
  // types this affix can be applied to (mage/warrior/both)
  const armor = typedKeys(ARMOR).reduce<Record<string, string>>((acc, armorSlot) => {
    const classes = [];

    if (appliesTo.includes(`${armorSlot}mage`)) {
      classes.push('Mage');
    }

    if (appliesTo.includes(`${armorSlot}melee`)) {
      classes.push('Warrior');
    }

    if (classes.length) {
      acc[armorSlot] = `${ARMOR[armorSlot]} (${classes.join(', ')})`;
    }

    return acc;
  }, {});

  // Jewelry list will be amulet, ring, or both
  const jewelry = typedKeys(JEWELRY)
    .filter((tag) => appliesTo.includes(tag))
    .map((tag) => JEWELRY[tag])
    .join(', ');

  // Weapon list is a bit more extensive, see the constant above.
  const weapons = typedKeys(WEAPONS)
    .filter((tag) => appliesTo.includes(tag))
    .map((tag) => WEAPONS[tag])
    .join(', ');

  // Merge the equipment slots together:
  const equipment = {
    ...armor,
    ...(!isEmpty(weapons) && { weapons }),
    ...(!isEmpty(jewelry) && { jewelry }),
  };

  return (
    <div className={styles.affix}>
      <div className={styles.header}>
        <DLCMarker item={affix} rarity="normal" />
        <span className={styles.name}>{affix.name}</span>
        {affix.properties.length > 1 && ' (Multiple possibilities)'}
      </div>
      <div className={styles.content}>
        <div className={styles.properties}>
          {affix.properties.map((properties, index) => (
            // eslint-disable-next-line
            <div className={styles.affixOption} key={index}>
              <Properties properties={properties} />
            </div>
          ))}
        </div>
        <div className={styles.equipment}>
          <div className={styles.equipmentHeader}>
            <FormattedMessage {...messages.equipment} />:
          </div>
          {Object.entries(equipment)
            .filter(([, value]) => value)
            .map(([key, value]) => (
              <div key={key}>{value}</div>
            ))}
          {isEmpty(equipment) && (
            <div className={styles.notification}>
              This affix seems to be unused, it is kept in the database for reference only.
            </div>
          )}
        </div>
      </div>
      <div className={styles.requirements}>
        Requirements
        <div className={styles.requirement}>{`LVL ${affix.levelRequirement}`}</div>
      </div>
    </div>
  );
};

export default AffixComponent;