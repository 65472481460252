import PropTypes from 'prop-types';
import React from 'react';
import { Property } from '../../../../../types/tqdb';
import Properties from '../../../Properties';
import styles from './SetBonus.css';

interface Props {
  bonus: Property;
  index: number;
  total: number;
}

const SetBonus = ({ bonus, index, total }: Props) => {
  return (
    <div className={styles.bonus}>
      <div className={styles.bonusHeader}>
        {`${index} `}
        out of
        {` ${Math.max(index, total)} `}
        set items:
      </div>
      <Properties properties={bonus} />
    </div>
  );
};

export default SetBonus;