import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../store';
import Equipment from '../index';
import styles from './Page.css';

const Page = () => {
  const { equipment, equipmentLinks } = useAppSelector((state) => state.data);
  const [tab, setTab] = useState(0);
  const { itemId } = useParams();

  if (!itemId) {
    return null;
  }

  const item = equipment[equipmentLinks[itemId]];

  const handleSetTab = (_: string, tab: number) => {
    setTab(tab);
  };

  return (
    <div className={styles.wrapper}>
      <Equipment item={item} limitHeight={false} setTab={handleSetTab} tab={tab} />
    </div>
  );
};

export default Page;
