import classNames from 'classnames';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Creature } from '../../../../types/tqdb';
import Loot from '../Loot';
import Details from './Details';
import styles from './Monster.css';

const DIFFICULTIES = ['Normal', 'Epic', 'Legendary'];
const TABS = ['Properties', 'Loot Table'];

interface Props {
  difficulty: number;
  monster: Creature;
  setDifficulty(monster: string, difficulty: number): void;
  setTab(monster: string, tab: number): void;
  tab: number;
}

const Monster = ({ difficulty, monster, setDifficulty, setTab, tab }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{monster.name}</div>
      <div className={styles.contentTabs}>
        {TABS.map((t, index) => {
          const className = classNames(styles.contentTab, {
            [styles.contentTabActive]: tab === index,
          });

          return (
            <button key={t} className={className} onClick={() => setTab(monster.tag, index)} type="button">
              {t}
            </button>
          );
        })}
        <div className={styles.contentTabBorder} />
      </div>
      <div className={styles.content}>
        <Scrollbars autoHeight autoHeightMax={284}>
          {tab === 0 && (
            <Details
              abilities={monster.abilities[difficulty]}
              level={monster.level[difficulty]}
              properties={monster.properties[difficulty]}
              race={monster.race}
            />
          )}
          {tab === 1 && <Loot difficulty={difficulty} monster={monster} />}
        </Scrollbars>
      </div>
      <div className={styles.footer}>
        {DIFFICULTIES.map((d, index) => {
          const className = classNames(styles.difficulty, {
            [styles.difficultyActive]: difficulty === index,
          });

          return (
            <button key={d} className={className} onClick={() => setDifficulty(monster.tag, index)} type="button">
              {d}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Monster;
