import classNames from 'classnames';
import React, { ReactNode } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Equipment } from '../../../../types/tqdb';
import rarities from '../../../css/rarities.css';
import { useAppSelector } from '../../../store';
import messages from '../../Layout/TopPanel/messages';
import Summon from '../../Summon';
import Loot from '../Loot';
import styles from './Base.css';
import DLCMarker from './DLCMarker';
import DropsIn from './DropsIn';
import Image from './Image';
import Requirements from './Requirements';
import SetPart from './SetPart';

const TABS = ['Properties', 'Loot Table'];

export interface BaseEquipmentProps {
  contentClass?: string;
  header?: ReactNode;
  headerClass?: string;
  imageClass?: string;
  item: Equipment;
  limitHeight: boolean;
  properties?: ReactNode;
  setTab(item: string, tab: number): void;
  tab: number;
}

const Base = ({
  contentClass: contentClassName,
  header,
  headerClass: headerClassName,
  imageClass,
  item,
  limitHeight,
  properties,
  setTab,
  tab,
}: BaseEquipmentProps) => {
  const { language } = useAppSelector(state => state.i18n);
  const intl = useIntl();

  const content = [
    <React.Fragment key={item.tag}>
      <SetPart set={item.set} />
      {properties}
      {item.summons && <Summon summon={item.summons} />}
    </React.Fragment>,
    <Loot key={`${item.tag}-drops`} item={item} />,
  ];

  // Setup the rarity classification map:
  const rarityMap = {
    [intl.formatMessage(messages.lesser)]: 'lesser',
    [intl.formatMessage(messages.greater)]: 'greater',
    [intl.formatMessage(messages.divine)]: 'divine',
    [intl.formatMessage(messages.normal)]: 'normal',
    [intl.formatMessage(messages.rare)]: 'rare',
    [intl.formatMessage(messages.epic)]: 'epic',
    [intl.formatMessage(messages.legendary)]: 'legendary',
  };

  // Setup the style class names:
  const contentClass = classNames(styles.content, contentClassName, {
    [styles.noscroll]: !limitHeight,
  });
  const headerClass = classNames(styles.header, headerClassName);
  const rarityClass = classNames(styles.rarityBorder, rarities[`${rarityMap[item.classification]}-bg`]);
  const wrapperClass = classNames(styles.wrapper, {
    [styles.noscroll]: !limitHeight,
  });

  return (
    <div className={wrapperClass}>
      <div className={rarityClass} />
      <div className={headerClass}>
        <DLCMarker item={item} rarity={rarityMap[item.classification]} />
        <Image className={imageClass} item={item} />
        <Link className={styles.title} to={`/${language}/equipment/${item.link}`}>
          {item.name}
        </Link>
        <div className={styles.headerLeft}>{header}</div>
        <DropsIn item={item} />
      </div>
      <div>
        <div className={styles.contentTabs}>
          {TABS.map((t, index) => {
            const className = classNames(styles.contentTab, {
              [styles.contentTabSelected]: tab === index,
            });

            return (
              <button key={t} className={className} onClick={() => setTab(item.link, index)} type="button">
                {t}
              </button>
            );
          })}
          <div className={styles.contentTabBorder} />
        </div>
        <div className={contentClass}>{!limitHeight ? content[tab] : <Scrollbars>{content[tab]}</Scrollbars>}</div>
      </div>
      <Requirements item={item} />
    </div>
  );
};

export default Base;
