import React from 'react';

const NotFound = () => (
  <React.Fragment>
    <h2>Page not found</h2>
    <div>
      The page you were looking for could not be found. It&#39;s possible it has moved since locale options were
      recently added to the website, which might have changed your URL.
      <br />
      <br />
      Please use the sidebar on the left to navigate.
    </div>
  </React.Fragment>
);

export default NotFound;
