import React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../store';
import Base from '../Base';
import { BaseEquipmentProps } from '../Base/Base';
import Bonus from '../Bonus';
import styles from './Formula.css';
import Reagent from './Reagent';

const reagentKeys: ('reagent1' | 'reagent2' | 'reagent3')[] = ['reagent1', 'reagent2', 'reagent3'];

const Formula = (props: BaseEquipmentProps) => {
  const equipment = useAppSelector((state) => state.data.equipment);
  const { language } = useAppSelector((state) => state.i18n);
  const artifact = equipment[props.item.tag.substring('formula-'.length)];
  const reagents = reagentKeys.map((reagent) => equipment[props.item[reagent]!]);

  // Header and properties for formula:
  const header = <div>{props.item.classification}</div>;
  const properties = (
    <div>
      <div className={styles.linkWrapper}>
        Creates{' '}
        <Link className={styles.link} to={`/${language}/equipment/${artifact.link}`}>
          {artifact.name}
        </Link>
      </div>
      Required reagents
      <ol className={styles.reagents}>
        {reagentKeys.map((reagent, index) => (
          <li key={reagent}>
            <Reagent item={reagents[index]} />
          </li>
        ))}
      </ol>
      <Bonus bonuses={props.item.bonus || []} />
    </div>
  );

  return (
    <Base
      contentClass={styles.content}
      header={header}
      headerClass={styles.header}
      imageClass={styles.image}
      properties={properties}
      {...props}
    />
  );
};

export default Formula;
