import React from 'react';
import PropTypes from 'prop-types';

const icons = {
  search: (
    <g>
      <path
        d="M15.072,17.309c-1.553,1.105-3.452,1.756-5.503,1.756c-5.242,
        0-9.492-4.25-9.492-9.492S4.327,0.08,9.569,0.08s9.492,4.25,9.492,
        9.492c0,2.051-0.65,3.95-1.756,5.503l6.235,6.235c0.615,0.615,0.609,
        1.598,0,2.207l-0.026,0.026c-0.608,0.608-1.598,0.609-2.207,0L15.072,
        17.309L15.072,17.309z M9.569,16.831c4.009,0,7.259-3.25,7.259-7.259
        s-3.25-7.259-7.259-7.259s-7.259,3.25-7.259,7.259S5.561,16.831,9.569,
        16.831L9.569,16.831z"
      />
    </g>
  ),
};

export default class Icon extends React.Component {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    viewBox: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    onClick: () => {},
    size: 16,
    viewBox: '0 0 24 24',
  };

  onClick = (e) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(e);
    }
  };

  render() {
    const { className, icon, size, viewBox } = this.props;

    return (
      <svg viewBox={viewBox} width={size} height={size} className={className} onClick={this.onClick}>
        {icons[icon]}
      </svg>
    );
  }
}
