import { AutoSizer, Grid, WindowScroller } from 'react-virtualized';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './VirtualCollection.css';

// These style properties are the only real ones necessary for the virtual grid:
const STYLES = ['left', 'position', 'top'];

export default class VirtualCollection extends React.Component {
  static propTypes = {
    gutter: PropTypes.number.isRequired,
    itemHeight: PropTypes.number.isRequired,
    itemWidth: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    renderItem: PropTypes.func.isRequired,
    passThroughProps: PropTypes.shape(),
  };

  static defaultProps = {
    passThroughProps: null,
  };

  renderCell = ({ columnIndex, key, rowIndex, style }) => {
    const { items, renderItem } = this.props;

    // Calculate item index based on items per row:
    const itemIndex = rowIndex * this.itemsPerRow + columnIndex;

    if (itemIndex < items.length) {
      return (
        <div key={key} className={styles.itemWrapper} style={pick(style, STYLES)}>
          {renderItem(items[itemIndex])}
        </div>
      );
    }

    return null;
  };

  render() {
    const { itemHeight, itemWidth, gutter, items, passThroughProps } = this.props;

    return (
      <WindowScroller>
        {({ height, scrollTop }) => (
          <AutoSizer disableHeight className={styles.autosizer}>
            {({ width }) => {
              this.itemsPerRow = Math.floor(width / (itemWidth + gutter)) || 1;
              const rowCount = Math.ceil(items.length / this.itemsPerRow);

              return (
                <Grid
                  autoHeight
                  cellRenderer={this.renderCell}
                  columnCount={this.itemsPerRow}
                  columnWidth={itemWidth + gutter}
                  height={height}
                  rowCount={rowCount}
                  rowHeight={itemHeight + gutter}
                  scrollTop={scrollTop}
                  width={width}
                  {...passThroughProps}
                />
              );
            }}
          </AutoSizer>
        )}
      </WindowScroller>
    );
  }
}
