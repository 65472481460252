import get from 'lodash/get';
import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useParams } from 'react-router-dom';
import { Equipment, EquipmentSet } from '../../../../types/tqdb';
import { useAppSelector } from '../../../store';
import EquipmentComponent from '../../Equipment';
import styles from './Details.css';
import SetBonus from './SetBonus';

interface Props {
  set?: EquipmentSet;
  setMembers?: Equipment[];
}

const SetDetails = (props: Props) => {
  const { setId } = useParams();
  const equipment = useAppSelector((state) => state.data.equipment);
  const sets = useAppSelector((state) => state.data.sets);
  const setLinks = useAppSelector((state) => state.data.setLinks);
  const [tabs, setTabs] = useState<Record<string, number>>({});

  const setTag = setId ? setLinks[setId] : '';
  const set = props.set || sets[setTag];
  const setMembers = props.setMembers || set.items.map((item) => equipment[item]);

  const handleTabChange = (item: string, tab: number) => {
    setTabs((prevTabs) => ({
      ...prevTabs,
      [item]: tab,
    }));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.set}>
        <div className={styles.header}>
          <div className={styles.title}>{set.name}</div>
        </div>
        <div className={styles.content}>
          <Scrollbars>
            {set.properties.map((bonus, i) => (
              <SetBonus key={`set-bonus-${i}`} bonus={bonus} index={i + 2} total={setMembers.length} />
            ))}
          </Scrollbars>
        </div>
      </div>
      {setMembers.map((member) => (
        <EquipmentComponent
          key={member.tag}
          item={member}
          limitHeight
          setTab={handleTabChange}
          tab={get(tabs, member.link, 0)}
        />
      ))}
    </div>
  );
};

export default SetDetails;
