import has from 'lodash/has';
import React from 'react';
import { Equipment, EquipmentLootEntry } from '../../../../types/tqdb';
import { useAppSelector } from '../../../store';
import styles from './Loot.css';

interface Props {
  item: Equipment;
}

const Loot = ({ item }: Props) => {
  const creatureLoot = useAppSelector((state) => state.data.creatureEquipmentLoot);
  const questLoot = useAppSelector((state) => state.data.questEquipmentLoot);
  let drops = [...(creatureLoot[item.tag] || []), ...(questLoot[item.tag] || [])];

  if (item.classification === 'Rare') {
    drops = item.icon && creatureLoot[item.icon] ? creatureLoot[item.icon] : [];
    // Only show loot from the same difficulty:
    drops = drops.filter((drop) => drop.difficulty.toLowerCase() === item.dropsIn!.toLowerCase());
  } else if (item.type === 'ItemArtifactFormula') {
    const artifactTag = item.tag.substring('formula-'.length);
    drops = [...(creatureLoot[artifactTag] || []), ...(questLoot[artifactTag] || [])];
  }

  // Sort by tag and then by chance:
  const table = Object.values(
    drops.reduce<Record<string, EquipmentLootEntry[]>>((acc, drop) => {
      if (has(acc, drop.tag)) {
        acc[drop.tag].push(drop);
        acc[drop.tag].sort((a, b) => b.chance - a.chance);
      } else {
        acc[drop.tag] = [drop];
      }

      return acc;
    }, {}),
  ).sort((a, b) => b[0].chance - a[0].chance);

  return (
    <div className={styles.wrapper}>
      {table.map((entry) => (
        <React.Fragment key={entry[0].tag}>
          <div className={styles.name}>{entry[0].name}</div>
          {entry.map((drop) => (
            <div key={`${drop.difficulty}-${drop.loot}`} className={styles.drop}>
              <div className={styles.difficulty}>{drop.difficulty}</div>
              <div className={styles.type}>{drop.loot}</div>
              <div className={styles.chance}>{drop.chance}%</div>
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Loot;
