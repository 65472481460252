import { defineMessages } from 'react-intl';

export default defineMessages({
  // Difficulties and rarities
  epic: 'Epic',
  normal: 'Normal',
  legendary: 'Legendary',
  rare: 'Rare',
  mi: 'Monster Infrequents',
  // Classifications
  lesser: 'Lesser Artifact',
  greater: 'Greater Artifact',
  divine: 'Divine Artifact',
});
