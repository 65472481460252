import omit from 'lodash/omit';
import React from 'react';
import Properties from '../../Properties';
import Base from '../Base';
import { BaseEquipmentProps } from '../Base/Base';
import styles from './Armor.css';

const Armor = (props: BaseEquipmentProps) => {
  // Prepare the armor specific variables:
  const armorValue = props.item.properties.defensiveProtection;
  const armorProperties = omit(props.item.properties, ['defensiveProtection']);
  const properties = <Properties properties={armorProperties} />;

  return (
    <Base
      contentClass={styles.content}
      headerClass={styles.header}
      imageClass={styles.image}
      header={armorValue}
      properties={properties}
      {...props}
    />
  );
};

export default Armor;
