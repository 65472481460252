import { defineMessages } from 'react-intl';

export default defineMessages({
  head: 'Head',
  arms: 'Arm',
  torso: 'Torso',
  legs: 'Leg',
  axe: 'Axe',
  spear: 'Spear',
  bow: 'Bow',
  staff: 'Staff',
  club: 'Club',
  sword: 'Sword',
  shield: 'Shield',
  throwing: 'Throwing',
  ring: 'Ring',
  amulet: 'Amulet',
  relic: 'Relic',
  charm: 'Charm',
  artifact: 'Artifact',
  formula: 'Formula',
  scroll: 'Scroll',
});
