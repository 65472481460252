import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import xor from 'lodash/xor';

export type UpdateFilter = 'classifications' | 'difficulties' | 'rarities';

interface FiltersState {
  classifications: string[];
  difficulties: string[];
  search: string;
  rarities: string[];
}

const initialState: FiltersState = {
  classifications: [],
  difficulties: [],
  search: '',
  rarities: [],
};

export const filtersSlice = createSlice({
  initialState,
  name: 'data',
  reducers: {
    initializeFilter(state, action: PayloadAction<{ filter: UpdateFilter; values: string[] }>) {
      state[action.payload.filter] = action.payload.values;
    },
    setSearch(state, action: PayloadAction<string | undefined>) {
      state.search = action.payload || '';
    },
    setUpdateFilter(state, action: PayloadAction<{ filter: UpdateFilter; value: string }>) {
      state[action.payload.filter] = xor(state[action.payload.filter], [action.payload.value]);
    },
  },
});

export const { initializeFilter, setSearch, setUpdateFilter } = filtersSlice.actions;
export default filtersSlice.reducer;
