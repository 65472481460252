import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import React from 'react';
import { Property } from '../../../../../types/tqdb';
import { useAppSelector } from '../../../../store';
import Properties from '../../../Properties';
import Skill from '../../../Skill';
import styles from './Details.css';

interface Props {
  abilities: Record<string, number>;
  level: number;
  properties: Property;
  race: string;
}

const Details = ({ abilities, level, properties, race }: Props) => {
  const skills = useAppSelector((state) => state.data.skills);

  // Extract separate properties:
  const healthAndMana = pick(properties, 'characterLife', 'characterMana');
  const offensive = pickBy(
    properties,
    (value, property) => property.startsWith('offensive') || property.startsWith('retaliation'),
  );
  const defensive = pickBy(properties, (value, property) => property.startsWith('defensive'));

  // Map abilities using skills:
  const abilitiesList = Object.entries(abilities).map(([tag, skillLevel]) => ({
    ...skills[tag],
    level: skillLevel,
  }));

  const paddedHeader = classNames(styles.header, styles.padded);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {level ? (
          <React.Fragment>
            <div className={styles.contentLeft}>
              Level {level} {race}
              <Properties properties={healthAndMana} />
              {!isEmpty(offensive) && (
                <React.Fragment>
                  <span className={paddedHeader}>Offensive</span>
                  <Properties properties={offensive} />
                </React.Fragment>
              )}
              <span className={paddedHeader}>Resistances</span>
              {!isEmpty(defensive) ? <Properties properties={defensive} /> : 'No resistances'}
            </div>
            <div className={styles.contentRight}>
              {!isEmpty(abilitiesList) && (
                <React.Fragment>
                  <span className={styles.header}>Abilities</span>
                  {abilitiesList.map((skill) => (
                    <div key={skill.tag} className={styles.ability}>
                      {skill.name || skill.description}
                      <Skill skill={skill} level={skill.level} />
                    </div>
                  ))}
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        ) : (
          "This monster doesn't spawn in this difficulty."
        )}
      </div>
    </div>
  );
};

export default Details;
