import React from 'react';
import { Property } from '../../../../types/tqdb';
import Properties from '../../Properties';
import styles from './Bonus.css';

interface Props {
  bonuses?: { chance: number; option: Property }[];
}

const Bonus = ({ bonuses }: Props) => {
  // Sort the bonuses on decreasing chance:
  const cloned = [...bonuses || []];
  cloned.sort((a, b) => b.chance - a.chance);

  return (
    <table className={styles.wrapper}>
      <thead>
        <tr>
          <th>Completion cloned</th>
          <th className={styles.chance}>Chance</th>
        </tr>
      </thead>
      <tbody>
        {cloned.map((bonus, j) => (
          <tr key={`bonus-${j}`} className="item-bonus">
            <td>
              <Properties properties={bonus.option} />
            </td>
            <td>{bonus.chance}%</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Bonus;
