import PropTypes from 'prop-types';
import React from 'react';
import omit from 'lodash/omit';

import Base from '../../Base';
import Properties from '../../../Properties';

export default class Shield extends React.Component {
  static propTypes = {
    item: PropTypes.shape().isRequired,
  };

  render() {
    const { item } = this.props;

    const block = item.properties.defensiveBlock;
    const damage = item.properties.offensivePhysical;
    const piercing = item.properties.offensivePierceRatio;

    // Remove the shield specific properties that are listed in the header:
    const shieldProperties = omit(item.properties, ['defensiveBlock', 'offensivePhysical', 'offensivePierceRatio']);

    // The properties and header for shield weapons:
    const properties = <Properties properties={shieldProperties} />;
    const header = (
      <div>
        {block}
        <br />
        {damage}
        <br />
        {piercing}
      </div>
    );

    return <Base header={header} properties={properties} {...this.props} />;
  }
}
