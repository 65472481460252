import classNames from 'classnames';
import React, { useState } from 'react';
import { Property } from '../../../types/tqdb';
import { useAppSelector } from '../../store';
import Skill from '../Skill';
import styles from './Properties.css';
import Sorted from './Sorted';

interface Props {
  className?: string;
  properties: Property;
}

const Properties = ({ className, properties }: Props) => {
  const skills = useAppSelector((state) => state.data.skills);
  const [showSkill, setShowSkill] = useState(false);
  const sorter = new Sorted();

  const handleShowSkill = () => {
    setShowSkill(!showSkill);
  };

  const sorted = sorter.sort(properties);
  const wrapperClass = classNames(styles.properties, className);

  return (
    <ul className={wrapperClass}>
      {sorted.map((key) => {
        let property = properties[key];

        if (property instanceof Array && key !== 'petBonus') {
          // Racial bonuses are arrays:
          return property.map((text, j) => (
            // eslint-disable-next-line
            <li key={`${key}-${j}`}>{text}</li>
          ));
        }

        if (property instanceof Object) {
          // Skill or mastery augments are objects:
          if ((key.includes('augmentSkillName') || key.includes('augmentMasteryName')) && 'name' in property) {
            return <li key={key}>{property.name}</li>;
          }

          if (key === 'itemSkillName') {
            const property = properties[key]!;
            const skill = skills[property.tag];

            // Skill granted items are also objects
            return (
              <li className={styles.grantsSkill} key={key}>
                Grants skill: {skill.name}
                {property.trigger} (
                <button className={styles.expand} onClick={handleShowSkill} type="button">
                  {showSkill ? 'hide details' : 'show details'}
                </button>
                )
                {!showSkill || (
                  <div className={styles.expanded}>
                    <Skill skill={skill} level={property.level} />
                  </div>
                )}
              </li>
            );
          }

          if (key === 'petBonus') {
            const property = properties[key]!;
            let petProperties = property;

            // XXX - Fix Spear of Nemetona
            if (property instanceof Array) {
              petProperties = property[0].petBonus;
            }

            // Lastly pet bonuses are objects:
            return (
              <li className={styles.petBonus} key={key}>
                <div className={styles.petBonusHeader}>Bonus to All Pets:</div>
                {Object.entries(petProperties).map(([propKey, propValue], j) => (
                  <div key={`${propKey}-${j}`}>{propValue as string}</div>
                ))}
              </li>
            );
          }

          if (key === 'offensiveGlobalChance' || key === 'retaliationGlobalChance') {
            const property = properties[key]!;
            return (
              <li key={key}>
                {property.chance}
                {Object.entries(property.properties).map(([propKey, propValue]) => (
                  <div key={propKey} className={styles.propertyChance}>
                    {propValue as string}
                  </div>
                ))}
              </li>
            );
          }
        }

        return <li key={key}>{property as string}</li>;
      })}
    </ul>
  );
};

export default Properties;
