import Markdown from 'react-remarkable';
import React from 'react';

import welcome from './welcome.md';
import styles from './Home.css';

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    // eslint-disable-next-line no-undef
    const rc = require.context('../../data/news/', false, /\.md$/);
    this.news = rc
      .keys()
      .map((key) => [
        // Grab the filename only:
        key.split('/').pop().split('.').shift(),
        // And grab the content of the file from the context:
        rc(key),
      ])
      .sort(([a], [b]) => b.localeCompare(a));
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.welcome}>
          <Markdown source={welcome} />
        </div>
        <h2>News & updates</h2>
        {this.news.map(([name, content]) => (
          <div className={styles.news} key={name}>
            <Markdown source={content} />
          </div>
        ))}
      </React.Fragment>
    );
  }
}
