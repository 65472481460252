import React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../store';
import styles from './SetPart.css';

interface Props {
  set?: string;
}

const SetPart = ({ set: setTag }: Props) => {
  const { language } = useAppSelector((state) => state.i18n);
  const sets = useAppSelector((state) => state.data.sets);

  if (!setTag || !sets[setTag]) {
    return null;
  }

  const set = sets[setTag];

  return (
    <div className={styles.wrapper}>
      Part of{' '}
      <Link to={`/${language}/set/${sets[setTag].link}`} className={styles.link}>
        {set.name}
      </Link>
    </div>
  );
};

export default SetPart;
