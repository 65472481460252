import React from 'react';
import { createRoot } from 'react-dom/client';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'typeface-open-sans';
import 'typeface-open-sans-condensed';
import '../sitemap.xml';
import AppProvider from './components/AppProvider';
import './css/global.css';
import { store } from './store';

const root = createRoot(document.getElementById('app')!);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={`/${store.getState().i18n.language}`} replace />} />
        <Route path="/:languagePrefix/*" element={<AppProvider />} />
      </Routes>
    </BrowserRouter>
  </Provider>,
);
