import { pick } from 'lodash';
import { AppThunk } from '.';
import {
  Creature,
  Datafile,
  Equipment,
  EquipmentCategory,
  EquipmentData,
  EquipmentLootEntry,
  EquipmentSetData,
  EquipmentSet,
  Quest,
} from '../../types/tqdb';
import messages from '../components/Layout/TopPanel/messages';
import getLink, { getUniqueSuffixValue } from '../util';
import {
  setCreatureEquipmentLoot,
  setCreatures,
  setEquipment,
  setEquipmentLinks,
  setLanguageLoaded,
  setLoading,
  setPrefixes,
  setQuestEquipmentLoot,
  setQuests,
  setSetLinks,
  setSets,
  setSkills,
  setSuffixes,
} from './data.slice';

export const DIFFICULTIES = ['Normal', 'Epic', 'Legendary'];

export const loadData = (): AppThunk => async (dispatch, getState) => {
  const { language, messages: languageMessages } = getState().i18n;

  dispatch(setLoading(true));

  // eslint-disable-next-line
  const data = require(`../data/data-${language}.json`);

  const response = await fetch(data).catch(() => null);
  dispatch(setLoading(false));

  if (!response || response.status >= 400) {
    // dispatch({
    //   type: LOAD_FAILURE,
    //   error: response.status,
    //   message: json,
    // });
  } else {
    const json = (await response.json()) as Datafile;

    dispatch(setLanguageLoaded(language));

    // Destruct the different data points:
    const {
      affixes: { prefixes, suffixes },
      creatures,
      equipment,
      quests,
      sets,
      skills,
    } = json;

    dispatch(setPrefixes(prefixes));
    dispatch(setSuffixes(suffixes));
    dispatch(loadCreatures(creatures));
    dispatch(loadEquipment(equipment, languageMessages[language][messages.rare.id as string]));
    dispatch(loadQuests(quests));
    dispatch(loadSets(sets));
    dispatch(setSkills(skills));
  }
};

const loadCreatures =
  (creatures: Record<string, Creature>): AppThunk =>
  (dispatch) => {
    // Iterate over the bosses and create a map from equipment to bosses:
    const creatureEquipmentLoot = Object.values(creatures).reduce<Record<string, EquipmentLootEntry[]>>(
      (acc, creature) => {
        // Pick the chest and loot properties from the creature
        Object.entries(pick(creature, 'chest', 'loot')).forEach(([type, loot]) => {
          // Per type of loot, iterate over the difficulties:
          loot.forEach((items, index) => {
            Object.entries(items).forEach(([tag, chance]) => {
              // Initialize array for this item tag if it's the first:
              if (!acc[tag]) {
                acc[tag] = [];
              }

              // Add the drop data to the item:
              acc[tag].push({
                tag: creature.tag,
                name: creature.name,
                chance,
                difficulty: DIFFICULTIES[index],
                loot: type === 'loot' ? 'equiped' : type,
              });
            });
          });
        });

        return acc;
      },
      {},
    );

    dispatch(setCreatures(creatures));
    dispatch(setCreatureEquipmentLoot(creatureEquipmentLoot));
  };

const loadEquipment =
  (equipmentMap: Record<EquipmentCategory, EquipmentData[]>, rare: string): AppThunk =>
  (dispatch) => {
    // Compile a list of all equipment:
    const equipmentLinks: Record<string, string> = {};
    const equipment = Object.keys(equipmentMap).reduce<Record<string, Equipment>>((result, category) => {
      const type = category as EquipmentCategory;

      // Add the link and type to each item:
      equipmentMap[type].forEach((item) => {
        // Filter for a single atlantis created issue:
        if (item.tag === 'Placeholder') {
          return;
        }

        let tag = '';
        let parsedItem = null;

        // Formula's have the same tag as their artifacts:
        if (type === 'ItemArtifactFormula') {
          tag = `formula-${item.tag}`;
          parsedItem = {
            ...item,
            icon: item.classification.toLowerCase(),
            link: getLink(`formula-${item.name}`),
            name: `Arcane Formula - ${item.name}`,
            tag,
            type,
          };
        } else if (item.classification === rare) {
          // Monster infrequents have the same tag, separate by difficulty:
          tag = `${item.tag}-${item.dropsIn!.toLowerCase()}`;

          while (result[tag]) {
            // Since Atlantis, some MI will have different base damages, but the same name & tag:
            tag = getUniqueSuffixValue(tag, `${item.tag}-${item.dropsIn!.toLowerCase()}`);
          }

          parsedItem = {
            ...item,
            icon: item.tag,
            link: getLink(`${item.name}-${item.dropsIn!.toLowerCase()}`),
            tag,
            type,
          };
        } else {
          tag = item.tag;
          parsedItem = {
            ...item,
            link: getLink(item.name),
            type,
          };
        }

        if (result[tag]) {
          // Skip any further duplicates, the important ones are handled during the MI parsing above
          return;
        }

        // Now assign the tag & item:
        result[tag] = parsedItem;

        // Now ensure unique links:
        if (parsedItem.link && equipmentLinks[parsedItem.link]) {
          const itemLink = getUniqueSuffixValue(parsedItem.link, getLink(item.name));
          result[parsedItem.tag].link = itemLink;
          equipmentLinks[decodeURIComponent(itemLink)] = tag;
        } else if (parsedItem.link) {
          equipmentLinks[decodeURIComponent(parsedItem.link)] = tag;
        }
      });

      return result;
    }, {});

    dispatch(setEquipment(equipment));
    dispatch(setEquipmentLinks(equipmentLinks));
  };

const loadQuests =
  (questMap: Record<string, Quest>): AppThunk =>
  (dispatch) => {
    // Iterate over the bosses and create a map from equipment to bosses:
    const questEquipmentLoot = Object.entries(questMap).reduce<Record<string, EquipmentLootEntry[]>>(
      (acc, [tag, quest]) => {
        // Each reward is tiered into the three difficulties:
        quest.rewards.forEach((difficulty, index) => {
          Object.entries(difficulty).forEach(([itemTag, chance]) => {
            // Initialize array for this item tag if it's the first:
            if (!acc[itemTag]) {
              acc[itemTag] = [];
            }

            // Add the drop data to the item:
            acc[itemTag].push({
              tag,
              name: quest.name,
              chance,
              difficulty: DIFFICULTIES[index],
              loot: 'quest',
            });
          });
        });

        return acc;
      },
      {},
    );

    dispatch(setQuests(questMap));
    dispatch(setQuestEquipmentLoot(questEquipmentLoot));
  };

const loadSets =
  (setMap: Record<string, EquipmentSetData>): AppThunk =>
  (dispatch) => {
    const setLinks: Record<string, string> = {};
    const sets = Object.keys(setMap).reduce<Record<string, EquipmentSet>>((result, key) => {
      const equipmentSet = setMap[key];
      const link = getLink(equipmentSet.name);

      result[key] = {
        ...equipmentSet,
        link,
      };

      // Add link for sets:
      setLinks[decodeURIComponent(link)] = key;
      return result;
    }, {});

    dispatch(setSets(sets));
    dispatch(setSetLinks(setLinks));
  };
