import React, { useCallback, useEffect } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import { loadData } from '../../../store/data.thunks';
import { initializeFilter, setSearch, setUpdateFilter, UpdateFilter } from '../../../store/filters.slice';
import { Language, languages, setLanguage } from '../../../store/i18n.slice';
import Icon from '../Icon';
import navMessages from '../Navigation/messages';
import messages from './messages';
import styles from './TopPanel.css';

const languageCodes: Record<Language, string[]> = {
  cs: ['CZ', 'čeština'],
  de: ['DE', 'Deutsche'],
  en: ['GB', 'English'],
  es: ['ES', 'Español'],
  fr: ['FR', 'Français'],
  it: ['IT', 'Italiano'],
  ja: ['JP', '日本人'],
  ko: ['KR', '한국어'],
  pl: ['PL', 'Polskie'],
  ru: ['RU', 'русский'],
  uk: ['UA', 'Українська'],
  zh: ['CN', '中文'],
};
const countries = languages.map((language) => languageCodes[language][0]);
const countryLabels = Object.values(languageCodes).reduce<Record<string, string>>((acc, [code, label]) => {
  acc[code] = label;
  return acc;
}, {});

const TopPanel = () => {
  const { language } = useAppSelector((state) => state.i18n);
  const { classifications, difficulties, rarities, search } = useAppSelector((state) => state.filters);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const initializeFilters = useCallback(() => {
    dispatch(
      initializeFilter({
        filter: 'classifications',
        values: [
          intl.formatMessage(messages.lesser),
          intl.formatMessage(messages.greater),
          intl.formatMessage(messages.divine),
        ],
      }),
    );
    dispatch(
      initializeFilter({
        filter: 'difficulties',
        values: [
          intl.formatMessage(messages.normal),
          intl.formatMessage(messages.epic),
          intl.formatMessage(messages.legendary),
        ],
      }),
    );
    dispatch(
      initializeFilter({
        filter: 'rarities',
        values: [
          intl.formatMessage(messages.rare),
          intl.formatMessage(messages.epic),
          intl.formatMessage(messages.legendary),
        ],
      }),
    );
  }, []);

  useEffect(() => {
    initializeFilters();
  }, [initializeFilters]);

  const handleFilterChange = (filter: UpdateFilter, value: string) => () => {
    dispatch(setUpdateFilter({ filter, value }));
  };

  const submitSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      // Submit search:
      const query = encodeURIComponent(search).replace(/%20/g, '+');
      navigate(`/${language}/search/${query}`);
    }
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(e.target.value));
  };

  const onSelect = (countryCode: string) => {
    // Find the matching locale for the country code:
    const locale = Object.keys(languageCodes).find((l) => languageCodes[l as Language][0] === countryCode) as Language;

    // Make sure the selected language wasn't the one already set:
    if (!locale || languageCodes[language][0] === countryCode) {
      return;
    }

    // Switch the language to the new locale
    dispatch(setLanguage(locale));

    // Initialize the new language filters and then load the new language
    initializeFilters();

    // Move to homepage
    navigate(`/${locale}`);

    // Load the new data
    dispatch(loadData());
  };

  const artifact = intl.formatMessage(navMessages.artifact).toLowerCase();
  const formula = intl.formatMessage(navMessages.formula).toLowerCase();
  const relic = intl.formatMessage(navMessages.relic).toLowerCase();
  const charm = intl.formatMessage(navMessages.charm).toLowerCase();
  const scroll = intl.formatMessage(navMessages.scroll).toLowerCase();
  const patternArtifactFormula = new RegExp(`/[a-zA-Z]{2}/category/(${artifact}|${formula})`);
  const patternRelicCharmScroll = new RegExp(`/[a-zA-Z]{2}/category/(${relic}|${charm}|${scroll})`);

  let topFilter = null;
  if (location.pathname.match(patternArtifactFormula)) {
    const lesser = intl.formatMessage(messages.lesser);
    const greater = intl.formatMessage(messages.greater);
    const divine = intl.formatMessage(messages.divine);

    // Artifact/formula filters:
    topFilter = (
      <div className={styles.rarityWrapper}>
        Show types:
        <div className={styles.rarityFilter}>
          <label className={styles.rarityLabel} htmlFor="show-lesser">
            <input
              type="checkbox"
              id="show-lesser"
              className={styles.rarityFilterInput}
              onChange={handleFilterChange('classifications', lesser)}
              checked={classifications.includes(lesser)}
            />
            <FormattedMessage {...messages.lesser} />
          </label>
        </div>
        <div className={styles.rarityFilter}>
          <label className={styles.rarityLabel} htmlFor="show-greater">
            <input
              type="checkbox"
              id="show-greater"
              className={styles.rarityFilterInput}
              onChange={handleFilterChange('classifications', greater)}
              checked={classifications.includes(greater)}
            />
            <FormattedMessage {...messages.greater} />
          </label>
        </div>
        <div className={styles.rarityFilter}>
          <label className={styles.rarityLabel} htmlFor="show-divine">
            <input
              type="checkbox"
              id="show-divine"
              className={styles.rarityFilterInput}
              onChange={handleFilterChange('classifications', divine)}
              checked={classifications.includes(divine)}
            />
            <FormattedMessage {...messages.divine} />
          </label>
        </div>
      </div>
    );
  } else if (location.pathname.match(patternRelicCharmScroll)) {
    const normal = intl.formatMessage(messages.normal);
    const epic = intl.formatMessage(messages.epic);
    const legendary = intl.formatMessage(messages.legendary);

    // Relic/Charm
    topFilter = (
      <div className={styles.rarityWrapper}>
        Show difficulties:
        <div className={styles.rarityFilter}>
          <label className={styles.rarityLabel} htmlFor="show-normal">
            <input
              type="checkbox"
              id="show-normal"
              className={styles.rarityFilterInput}
              onChange={handleFilterChange('difficulties', normal)}
              checked={difficulties.includes(normal)}
            />
            <FormattedMessage {...messages.normal} />
          </label>
        </div>
        <div className={styles.rarityFilter}>
          <label className={styles.rarityLabel} htmlFor="show-epic">
            <input
              type="checkbox"
              id="show-epic"
              className={styles.rarityFilterInput}
              onChange={handleFilterChange('difficulties', epic)}
              checked={difficulties.includes(epic)}
            />
            <FormattedMessage {...messages.epic} />
          </label>
        </div>
        <div className={styles.rarityFilter}>
          <label className={styles.rarityLabel} htmlFor="show-legendary">
            <input
              type="checkbox"
              id="show-legendary"
              className={styles.rarityFilterInput}
              onChange={handleFilterChange('difficulties', legendary)}
              checked={difficulties.includes(legendary)}
            />
            <FormattedMessage {...messages.legendary} />
          </label>
        </div>
      </div>
    );
  } else if (location.pathname.includes('/category')) {
    const rare = intl.formatMessage(messages.rare);
    const epic = intl.formatMessage(messages.epic);
    const legendary = intl.formatMessage(messages.legendary);

    // Other equipment:
    topFilter = (
      <div className={styles.rarityWrapper}>
        Show rarities:
        <div className={styles.rarityFilter}>
          <label className={styles.rarityRare} htmlFor="show-mi">
            <input
              type="checkbox"
              id="show-mi"
              className={styles.rarityFilterInput}
              onChange={handleFilterChange('rarities', rare)}
              checked={rarities.includes(rare)}
            />
            <FormattedMessage {...messages.mi} />
          </label>
        </div>
        <div className={styles.rarityFilter}>
          <label className={styles.rarityEpic} htmlFor="show-epic">
            <input
              type="checkbox"
              id="show-epic"
              className={styles.rarityFilterInput}
              onChange={handleFilterChange('rarities', epic)}
              checked={rarities.includes(epic)}
            />
            <FormattedMessage {...messages.epic} />
          </label>
        </div>
        <div className={styles.rarityFilter}>
          <label className={styles.rarityLegendary} htmlFor="show-legendary">
            <input
              type="checkbox"
              id="show-legendary"
              className={styles.rarityFilterInput}
              onChange={handleFilterChange('rarities', legendary)}
              checked={rarities.includes(legendary)}
            />
            <FormattedMessage {...messages.legendary} />
          </label>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.topPanel}>
      <div className={styles.searchWrapper}>
        <button className={styles.searchClear} onClick={() => dispatch(setSearch())} type="button">
          {search.length > 0 ? 'X' : <Icon icon="search" className={styles.searchIcon} size="16" />}
        </button>
        <input
          type="text"
          className={styles.searchInput}
          placeholder="Filter or hit enter to search"
          value={search}
          onChange={onSearchChange}
          onKeyUp={submitSearch}
        />
      </div>
      {topFilter}
      <div className={styles.languageWrapper}>
        <ReactFlagsSelect
          className={styles.language}
          selectButtonClassName={styles.languageButton}
          countries={countries}
          customLabels={countryLabels}
          selected={languageCodes[language][0]}
          onSelect={onSelect}
          optionsSize={14}
        />
      </div>
    </div>
  );
};

export default TopPanel;
