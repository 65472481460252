import last from 'lodash/last';
import React from 'react';
import Properties from '../../Properties';
import Base from '../Base';
import { BaseEquipmentProps } from '../Base/Base';
import Bonus from '../Bonus';
import styles from './Relic.css';

const CLEANING_REGEX = /{.*?}/g;

const Relic = (props: BaseEquipmentProps) => {
  const description = props.item.description || '';

  const properties = (
    <div>
      <div className={styles.description}>{description.replace(CLEANING_REGEX, '')}</div>
      <div className={styles.title}>
        Completed
        {props.item.type === 'ItemCharm' ? ' charm' : ' relic'}
      </div>
      <Properties
        properties={Array.isArray(props.item.properties) ? last(props.item.properties) : props.item.properties}
      />
      <Bonus bonuses={props.item.bonus} />
    </div>
  );

  return (
    <Base
      contentClass={styles.content}
      headerClass={styles.header}
      imageClass={styles.image}
      properties={properties}
      {...props}
    />
  );
};

export default Relic;
