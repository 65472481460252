import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../Navigation';
import styles from './SidePanel.css';

const SidePanel = () => {
  // eslint-disable-next-line no-undef
  const version = `v${VERSION}`;

  return (
    <div className={styles.wrapper}>
      <Link to="/" className={styles.title} title="TQDatabase">
        <span className={styles.titleBold}>TQ</span>
        Database <span className={styles.version}>{version}</span>
      </Link>
      <Navigation />
    </div>
  );
};

export default SidePanel;
