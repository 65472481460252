import React from 'react';
import { Summon } from '../../../types/tqdb';
import { useAppSelector } from '../../store';

import Properties from '../Properties';
import styles from './Summon.css';

const ATTRIBUTES = ['spawnObjectsTimeToLive', 'characterLife', 'characterMana', 'offensivePhysical'];

interface Props {
  difficulty?: number;
  summon: Summon;
}

const SummonComponent = ({ difficulty = 0, summon }: Props) => {
  const skills = useAppSelector((state) => state.data.skills);

  // Initialize the different sections:
  let attributes: any[] = [];
  let resistances: any[] = [];
  let abilities: any[] = [];

  // Find and sort the attributes
  if (summon.properties) {
    attributes = Object.entries(summon.properties)
      .filter(([key]) => ATTRIBUTES.includes(key))
      .sort(([a], [b]) => ATTRIBUTES.indexOf(a) - ATTRIBUTES.indexOf(b));

    // Find the defensive resistances:
    resistances = Object.entries(summon.properties).filter(([key]) => key.startsWith('defensive'));
  }

  if (summon.abilities && difficulty < summon.abilities.length) {
    abilities = Object.entries(summon.abilities[difficulty]).map(([tag, level]) => ({
      ...skills[tag],
      level,
    }));
  }

  return (
    <div>
      {attributes.length > 0 && (
        <>
          {`${summon.name} Attributes`}:
          <div className={styles.attributes}>
            {attributes.map(([key, value]) => (
              <span className={styles.attribute} key={key}>
                {value}
              </span>
            ))}
          </div>
        </>
      )}
      {resistances.length > 0 && (
        <>
          {`${summon.name} Resistances`}:
          <div className={styles.attributes}>
            {resistances.map(([key, value]) => (
              <span className={styles.attribute} key={key}>
                {value}
              </span>
            ))}
          </div>
        </>
      )}
      {abilities.length > 0 && (
        <>
          {`${summon.name} Abilities`}:
          <div>
            {abilities.map((skill) => (
              <div key={skill.tag} className={styles.ability}>
                {skill.name || skill.description}
                {skill.properties && skill.properties[skill.level - 1] && (
                  <Properties properties={skill.properties[skill.level - 1]} />
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SummonComponent;