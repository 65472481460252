import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import { Equipment, Quest } from '../../../../types/tqdb';
import { useAppSelector } from '../../../store';

import styles from './Quest.css';

const DIFFICULTIES = ['Normal', 'Epic', 'Legendary'];

interface Reward {
  chance: number;
  item: Equipment;
}

interface Props {
  quest: Quest;
}

const QuestComponent = ({ quest }: Props) => {
  const equipment = useAppSelector((state) => state.data.equipment);
  const { language } = useAppSelector((state) => state.i18n);
  const [difficulty, setDifficulty] = useState(0);
  const [rewards, setRewards] = useState<Reward[]>([]);

  useEffect(() => {
    // Iterate over each rewards in this difficulty and map it to equipment:
    const rewards = Object.entries(quest.rewards[difficulty])
      .reduce<Reward[]>((result, [tag, chance]) => {
        if (!equipment[tag] && equipment[`${tag}-${DIFFICULTIES[difficulty]}`]) {
          // Push the MI item for this difficulty:
          result.push({
            chance,
            item: equipment[`${tag}-${DIFFICULTIES[difficulty]}`],
          });
        } else {
          result.push({
            chance,
            item: equipment[tag],
          });
        }

        return result;
      }, [])
      .sort((a, b) => b.chance - a.chance);

    setRewards(rewards);
  }, [difficulty, equipment, quest]);

  const getLocation = (quest: string) => {
    if (quest.startsWith('tagrumorgr')) {
      return 'Greek';
    }

    if (quest.startsWith('tagrumoreg')) {
      return 'Egypt';
    }

    if (quest.startsWith('tagrumoror')) {
      return 'Orient';
    }

    if (quest.startsWith('xtag')) {
      return 'Hades';
    }

    if (quest.startsWith('x2tag')) {
      return 'The North';
    }

    if (quest.startsWith('x3tag')) {
      return 'Atlantis';
    }

    if (quest.startsWith('x4tag')) {
      return 'Eternal Embers';
    }

    return null;
  };

  return (
    <div key={quest.tag} className={styles.wrapper}>
      <div className={styles.header}>
        {quest.name}
        <span className={styles.location}>{getLocation(quest.tag)}</span>
      </div>
      <div className={styles.content}>
        <Scrollbars autoHeight>
          {rewards.length
            ? rewards.map((loot) => (
                <div key={`${loot.item.tag}`} className={styles.loot}>
                  <span className={styles.lootName}>
                    <Link className={styles.title} to={`/${language}/equipment/${loot.item.link}`}>
                      {loot.item.name}
                    </Link>
                  </span>
                  <span className={styles.lootChance}>{loot.chance}%</span>
                </div>
              ))
            : 'This quest has no epic or legendary rewards in this difficulty.'}
        </Scrollbars>
      </div>
      <div className={styles.footer}>
        {DIFFICULTIES.map((d, index) => {
          const className = classNames(styles.difficulty, {
            [styles.difficultyActive]: difficulty === index,
          });

          return (
            <button key={d} className={className} onClick={() => setDifficulty(index)} type="button">
              {d}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default QuestComponent;
