import React from 'react';

import styles from './Footer.css';

export default class Footer extends React.Component {
  render() {
    return (
      <div className={styles.wrapper}>
        Titan Quest is a registered trademark of{' '}
        <a href="http://nordicgames.at/" className={styles.link} target="_new" rel="noopener noreferrer">
          THQNordic
        </a>
      </div>
    );
  }
}
