import has from 'lodash/has';
import React from 'react';
import { Property, Skill } from '../../../types/tqdb';
import { typedKeys } from '../../util';
import Properties from '../Properties';
import Summon from '../Summon';
import styles from './Skill.css';

interface Props {
  level: number;
  skill: Skill;
}

const SkillComponent = ({ level = 0, skill }: Props) => {
  let properties: Property = {};
  let header = null;

  // Initialize the properties to the correct level
  if (has(skill, 'properties') && skill.properties[level - 1]) {
    properties = skill.properties[level - 1];

    // Header fields for the skill (mana cost, duration, radius)
    const headerProperties: (keyof Property)[] = [
      'skillCooldownTime',
      'skillChanceWeight',
      'skillProjectileNumber',
      'projectileLaunchNumber',
      'skillManaCost',
      'skillActiveDuration',
      'skillTargetRadius',
      'projectileExplosionRadius',
    ];

    header = headerProperties
      .filter((property) => has(properties, property))
      .map((property) => <div key={property}>{String(properties[property])}</div>);

    // Filter out all the header properties:
    properties = typedKeys(properties).reduce<Property>((acc, property) => {
      if (!headerProperties.includes(property)) {
        // Coerce the value since it can't infer it correctly
        acc[property] = properties[property] as any;
      }

      return acc;
    }, {});
  }

  return (
    <React.Fragment>
      <div className={styles.header}>{header}</div>
      <Properties className={styles.properties} properties={properties} />
      {skill.summons && level < skill.summons.length && <Summon summon={skill.summons[level - 1]} />}
    </React.Fragment>
  );
};

export default SkillComponent;
