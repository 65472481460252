import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '.';
import { Property, Skill, ValueOf } from '../../types/tqdb';
import messageDescriptors from '../components/Layout/Navigation/messages';
import { selectMessages } from './i18n.selectors';

export const selectAffixes = createSelector(
  (state: RootState) => state.data.prefixes,
  (state: RootState) => state.data.suffixes,
  (state: RootState) => state.filters.search,
  (prefixes, suffixes, search) => {
    const filter = (affixes: Record<string, any>) =>
      Object.keys(affixes)
        .filter((key) => affixes[key].name.toLowerCase().includes(search))
        .map((key) => ({ ...affixes[key], tag: key }))
        .sort((a, b) => a.name.localeCompare(b.name));

    return {
      prefixes: filter(prefixes),
      suffixes: filter(suffixes),
    };
  },
);

export const selectCategoryEquipment = createSelector(
  (state: RootState) => state.data.equipment,
  (state: RootState) => state.data.skills,
  (state: RootState) => state.filters,
  selectMessages,
  (_: RootState, categoryId: string) => categoryId,
  (equipment, skills, { classifications, difficulties, rarities, search }, messages, categoryId) => {
    // Create the language specific category map:
    const languageMap = Object.entries(messageDescriptors).reduce<Record<string, string>>((acc, [key, descriptor]) => {
      const messageId = descriptor.id!;
      acc[key] = messages[messageId].toLowerCase();
      return acc;
    }, {});

    const linkMap = linkToTagMap(languageMap);
    const category = linkMap[categoryId];
    const artifact = languageMap.artifact.toLowerCase();
    const formula = languageMap.formula.toLowerCase();
    const relic = languageMap.relic.toLowerCase();
    const charm = languageMap.charm.toLowerCase();
    const scroll = languageMap.scroll.toLowerCase();

    // Grab from this category first:
    let items = Object.values(equipment).filter((item) => item.type === category);

    // Now apply the filters based on the type of category this is:
    if (categoryId === artifact || categoryId === formula) {
      items = items.filter((item) => classifications.includes(item.classification));
    } else if (categoryId === relic || categoryId === charm || categoryId === scroll) {
      items = items.filter((item) => difficulties.includes(item.classification));
    } else {
      items = items.filter((item) => rarities.includes(item.classification));
    }

    // If a search query was entered, match by name or properties:
    if (search) {
      items = items.filter((item) => {
        let properties: ValueOf<Property>[] = [];

        // If the properties are tiered, only grab the last one
        // eg. Relic/Charms have their partial completion properties as tiers
        if (item.properties && Array.isArray(item.properties)) {
          properties = Object.values(item.properties[item.properties.length - 1]);
        } else if (item.properties) {
          properties = Object.values(item.properties);
        }

        // Now iterate over all properties and concatenate into a single string:
        let propertyString = properties.map((p) => getPropertyString(p, skills)).join();

        // If the item has any completion bonuses, add those too:
        if (item.bonus) {
          // Append the completion bonus text strings
          propertyString += item.bonus
            .map((bonus) =>
              Object.values(bonus.option)
                .map((p) => getPropertyString(p, skills))
                .join(),
            )
            .join();
        }

        // Check the items name and properties for the search query:
        return `${item.name}${propertyString}`.toLowerCase().includes(search);
      });
    }

    // Lastly sort the items alphabetically
    items.sort((a, b) => a.name.localeCompare(b.name));
    return items;
  },
);

export const selectCreatures = createSelector(
  (state: RootState) => state.data.creatures,
  (state: RootState) => state.filters.search,
  (creatures, search) => {
    return Object.values(creatures)
      .filter((creature) => creature.name.toLowerCase().includes(search))
      .sort((a, b) => a.name.localeCompare(b.name));
  },
);

export const selectEquipmentSets = createSelector(
  (state: RootState) => state.data.sets,
  (state: RootState) => state.data.equipment,
  (state: RootState) => state.filters.search,
  (sets, equipment, search) => {
    return Object.keys(sets)
      .filter((key) => sets[key].name.toLowerCase().includes(search))
      .map((key) => {
        const set = sets[key];
        const setMembers = set.items.map((item) => equipment[item]);

        return {
          set: {
            ...set,
            tag: key,
          },
          setMembers,
        };
      })
      .sort((a, b) => a.set.name.localeCompare(b.set.name));
  },
);

export const selectQuests = createSelector(
  (state: RootState) => state.data.quests,
  (state: RootState) => state.filters.search,
  (quests, search) => {
    return Object.entries(quests)
      .map(([tag, quest]) => ({ ...quest, tag }))
      .filter((quest) => quest.name.toLowerCase().includes(search))
      .sort((a, b) => a.name.localeCompare(b.name));
  },
);

// The mapping of links to their equipment tags:
export const linkToTagMap = (language: any): Record<string, string> => ({
  // Armor
  [language.head]: 'ArmorProtective_Head',
  [language.arms]: 'ArmorProtective_Forearm',
  [language.torso]: 'ArmorProtective_UpperBody',
  [language.legs]: 'ArmorProtective_LowerBody',
  // Weapons
  [language.axe]: 'WeaponMelee_Axe',
  [language.spear]: 'WeaponHunting_Spear',
  [language.bow]: 'WeaponHunting_Bow',
  [language.staff]: 'WeaponMagical_Staff',
  [language.club]: 'WeaponMelee_Mace',
  [language.sword]: 'WeaponMelee_Sword',
  [language.shield]: 'WeaponArmor_Shield',
  [language.throwing]: 'WeaponHunting_RangedOneHand',
  // Jewelry
  [language.ring]: 'ArmorJewelry_Ring',
  [language.amulet]: 'ArmorJewelry_Amulet',
  // Crafting
  [language.relic]: 'ItemRelic',
  [language.charm]: 'ItemCharm',
  [language.artifact]: 'ItemArtifact',
  [language.formula]: 'ItemArtifactFormula',
  [language.scroll]: 'OneShot_Scroll',
});

/**
 * Returns the user friendly text string of a property
 *
 * @param {*} property - Object or string holding property information
 * @param {Object} skills - Dictionary of all known skills
 *
 */
function getPropertyString(property: ValueOf<Property>, skills: Record<string, Skill>): string {
  if (!property) {
    return '';
  }

  // The property is already just a string, just return it:
  if (typeof property === 'string') {
    return property;
  }

  // Simply return the joined result of all properties in the array:
  if (Array.isArray(property)) {
    return property.map((p) => getPropertyString(p, skills)).join();
  }

  // The user friendly text is located in the name property:
  if ('name' in property) {
    return property.name;
  }

  // The user friendly text has to be pulled from the skill using the tag:
  if ('tag' in property) {
    return skills[property.tag].name;
  }

  // The user friendly text is the joined result of all the properties within the chance:
  if ('chance' in property) {
    return Object.values(property.properties)
      .map((p) => getPropertyString(p, skills))
      .join();
  }

  return '';
}
