import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Affix, Creature, Equipment, EquipmentLootEntry, EquipmentSet, Quest, Skill } from '../../types/tqdb';

interface DataState {
  creatureEquipmentLoot: Record<string, EquipmentLootEntry[]>;
  creatures: Record<string, Creature>;
  equipment: Record<string, Equipment>;
  equipmentLinks: Record<string, string>;
  languageLoaded: string;
  loading: boolean;
  prefixes: Record<string, Affix>;
  questEquipmentLoot: Record<string, EquipmentLootEntry[]>;
  quests: Record<string, Quest>;
  setLinks: Record<string, string>;
  sets: Record<string, EquipmentSet>;
  skills: Record<string, Skill>;
  suffixes: Record<string, Affix>;
}

const initialState: DataState = {
  creatures: {},
  creatureEquipmentLoot: {},
  equipment: {},
  equipmentLinks: {},
  languageLoaded: '',
  loading: false,
  prefixes: {},
  questEquipmentLoot: {},
  quests: {},
  setLinks: {},
  sets: {},
  skills: {},
  suffixes: {},
};

export const dataSlice = createSlice({
  initialState,
  name: 'data',
  reducers: {
    setCreatureEquipmentLoot(state, action: PayloadAction<DataState['creatureEquipmentLoot']>) {
      state.creatureEquipmentLoot = action.payload;
    },
    setCreatures(state, action: PayloadAction<DataState['creatures']>) {
      state.creatures = action.payload;
    },
    setEquipment(state, action: PayloadAction<DataState['equipment']>) {
      state.equipment = action.payload;
    },
    setEquipmentLinks(state, action: PayloadAction<DataState['equipmentLinks']>) {
      state.equipmentLinks = action.payload;
    },
    setLanguageLoaded(state, action: PayloadAction<string>) {
      state.languageLoaded = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPrefixes(state, action: PayloadAction<DataState['prefixes']>) {
      state.prefixes = action.payload;
    },
    setQuestEquipmentLoot(state, action: PayloadAction<DataState['questEquipmentLoot']>) {
      state.questEquipmentLoot = action.payload;
    },
    setQuests(state, action: PayloadAction<DataState['quests']>) {
      state.quests = action.payload;
    },
    setSetLinks(state, action: PayloadAction<DataState['setLinks']>) {
      state.setLinks = action.payload;
    },
    setSets(state, action: PayloadAction<DataState['sets']>) {
      state.sets = action.payload;
    },
    setSkills(state, action: PayloadAction<DataState['skills']>) {
      state.skills = action.payload;
    },
    setSuffixes(state, action: PayloadAction<DataState['suffixes']>) {
      state.suffixes = action.payload;
    },
  },
});

export const {
  setCreatureEquipmentLoot,
  setCreatures,
  setEquipment,
  setEquipmentLinks,
  setLanguageLoaded,
  setLoading,
  setPrefixes,
  setQuestEquipmentLoot,
  setQuests,
  setSetLinks,
  setSets,
  setSkills,
  setSuffixes,
} = dataSlice.actions;
export default dataSlice.reducer;
