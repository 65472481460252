import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Equipment } from '../../../../../types/tqdb';
import { useAppSelector } from '../../../../store';
import messages from '../../../Layout/Navigation/messages';
import styles from './Reagent.css';

interface Props {
  item: Equipment;
}

const Reagent = ({ item }: Props) => {
  const { language } = useAppSelector((state) => state.i18n);
  let label = null;

  if (item.type === 'ItemArtifact') {
    label = ` - ${item.classification}`;
  } else if (item.type === 'ItemRelic') {
    label = (
      <>
        {' - '}
        <FormattedMessage {...messages.relic} />
      </>
    );
  } else if (item.type === 'ItemCharm') {
    label = (
      <>
        {' - '}
        <FormattedMessage {...messages.charm} />
      </>
    );
  } else if (item.type === 'OneShot_Scroll') {
    label = (
      <>
        {' - '}
        <FormattedMessage {...messages.scroll} />
      </>
    );
  }

  return (
    <div className={styles.wrapper} key={item.tag}>
      <Link className={styles.link} to={`/${language}/equipment/${item.link}`}>
        {item.name}
      </Link>
      {label}
    </div>
  );
};

export default Reagent;
