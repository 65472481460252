import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Equipment } from '../../../types/tqdb';
import { useAppSelector } from '../../store';
import EquipmentComponent from '../Equipment';
import styles from './Search.css';

const Search = () => {
  const equipment = useAppSelector((state) => state.data.equipment);
  const { query } = useParams();
  const [tabs, setTabs] = useState<Record<string, number>>({});
  const [results, setResults] = useState<Equipment[]>([]);

  useEffect(() => {
    if (!query) {
      setResults([]);
    } else {
      setResults(
        Object.values(equipment).filter((item) => {
          const found = encodeURIComponent(item.name).replace(/%20/g, '+').toLowerCase().includes(query.toLowerCase());
          return found && Object.keys(item).length > 0;
        }),
      );
    }
  }, [equipment, query]);

  const handleSetTab = (item: string, tab: number) => {
    setTabs({
      ...tabs,
      [item]: tab,
    });
  };

  if (query && query.length >= 3) {
    if (results.length === 0) {
      return (
        <div>
          <h2>No results</h2>
          No items were found matching your query.
        </div>
      );
    }

    return (
      <div className={styles.wrapper}>
        {results.map((item) => (
          <EquipmentComponent
            key={item.tag}
            item={item}
            limitHeight
            setTab={handleSetTab}
            tab={get(tabs, item.link, 0)}
          />
        ))}
      </div>
    );
  }

  return (
    <div>
      <h2>Query too short</h2>
      Please enter at least 3 characters in the search input.
    </div>
  );
};

export default Search;
