import has from 'lodash/has';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Creature, Equipment } from '../../../../types/tqdb';
import { useAppSelector } from '../../../store';
import styles from './Loot.css';

const DIFFICULTIES = ['normal', 'epic', 'legendary'];

interface LootEntry {
  chance: number;
  from: string;
  item: Equipment;
}

interface Props {
  difficulty: number;
  monster: Creature;
}

const Loot = ({ difficulty, monster }: Props) => {
  const equipment = useAppSelector((state) => state.data.equipment);
  const { language } = useAppSelector((state) => state.i18n);
  const [table, setTable] = useState<LootEntry[]>([]);

  useEffect(() => {
    // Construct the loot table by combining chest and equipped loot:
    let table: LootEntry[] = [];

    if (has(monster, 'chest')) {
      table = Object.entries(monster.chest[difficulty]).reduce<LootEntry[]>(
        (result, [tag, chance]) => {
          result.push({
            chance,
            from: 'Chest',
            item: equipment[tag],
          });

          return result;
        },
        // Initialize with an empty array
        [],
      );
    }

    if (has(monster, 'loot')) {
      table = Object.entries(monster.loot[difficulty]).reduce<LootEntry[]>(
        (result, [tag, chance]) => {
          if (!equipment[tag] && equipment[`${tag}-${DIFFICULTIES[difficulty]}`]) {
            // Push the MI item for this difficulty:
            result.push({
              chance,
              from: 'Equipped',
              item: equipment[`${tag}-${DIFFICULTIES[difficulty]}`],
            });
          } else {
            result.push({
              chance,
              from: 'Equipped',
              item: equipment[tag],
            });
          }

          return result;
        },
        // Initialize with the table in case chest loot was loaded
        table,
      );
    }

    table = table.filter((loot) => !!loot.item);
    table.sort((a, b) => b.chance - a.chance);
    setTable(table);
  }, [difficulty, equipment, monster]);

  return (
    <div className={styles.wrapper}>
      {table.map((loot) => (
        <div key={`${loot.from}-${loot.item.tag}`} className={styles.loot}>
          <span className={styles.lootName}>
            <Link className={styles.title} to={`/${language}/equipment/${loot.item.link}`}>
              {loot.item.name}
            </Link>
          </span>
          <span className={styles.lootFrom}>{loot.from}</span>
          <span className={styles.lootChance}>{loot.chance}%</span>
        </div>
      ))}
    </div>
  );
};

export default Loot;
