import PropTypes from 'prop-types';
import React from 'react';
import omit from 'lodash/omit';

import Base from '../Base';
import Properties from '../../Properties';

export default class Weapon extends React.Component {
  static propTypes = {
    item: PropTypes.shape().isRequired,
  };

  render() {
    const { item } = this.props;

    const damage = item.properties.offensivePhysical;
    const piercing = item.properties.offensivePierceRatio;
    const speed = item.properties.characterAttackSpeed;

    const weaponProperties = omit(item.properties, [
      'characterAttackSpeed',
      'offensivePhysical',
      'offensivePierceRatio',
    ]);

    // The properties and header for regular weapons:
    const properties = <Properties properties={weaponProperties} />;
    const header = (
      <div>
        <div>{damage}</div>
        <div>{piercing}</div>
        {speed}
      </div>
    );

    return <Base header={header} properties={properties} {...this.props} />;
  }
}
