import PropTypes from 'prop-types';
import React from 'react';
import omit from 'lodash/omit';

import Base from '../../Base';
import Properties from '../../../Properties';
import styles from './Staff.css';

const bases = [
  'offensiveBaseCold',
  'offensiveBaseFire',
  'offensiveBaseLightning',
  'offensivePhysical',
  'offensiveBasePoison',
  'offensiveBaseLife',
];

export default class Staff extends React.Component {
  static propTypes = {
    item: PropTypes.shape().isRequired,
  };

  render() {
    const { item } = this.props;

    const speed = item.properties.characterAttackSpeed;
    // Extract the base damage(s) for this staff from its properties:
    const baseDamages = bases.filter((base) => item.properties[base]);
    // Now omit the base damages and attack speed from properties:
    const magicProperties = omit(item.properties, [...baseDamages, 'characterAttackSpeed']);

    // The properties and header for staff weapons:
    const properties = <Properties properties={magicProperties} />;
    const header = (
      <div>
        {baseDamages.map((base) => (
          <div key={base} className={styles.damage}>
            {item.properties[base]}
          </div>
        ))}
        {speed}
      </div>
    );

    return <Base header={header} properties={properties} {...this.props} />;
  }
}
