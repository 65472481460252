import cs_locale from '../../messages/cs.yml';
import de_locale from '../../messages/de.yml';
import en_locale from '../../messages/en.yml';
import es_locale from '../../messages/es.yml';
import fr_locale from '../../messages/fr.yml';
import it_locale from '../../messages/it.yml';
import ja_locale from '../../messages/ja.yml';
import ko_locale from '../../messages/ko.yml';
import pl_locale from '../../messages/pl.yml';
import ru_locale from '../../messages/ru.yml';
import uk_locale from '../../messages/uk.yml';
import zh_locale from '../../messages/zh.yml';

export const loadLocaleMessages = (locale: string) => {
  switch (locale) {
    case 'cs':
      return cs_locale;
    case 'de':
      return de_locale;
    case 'en':
      return en_locale;
    case 'es':
      return es_locale;
    case 'fr':
      return fr_locale;
    case 'it':
      return it_locale;
    case 'ja':
      return ja_locale;
    case 'ko':
      return ko_locale;
    case 'pl':
      return pl_locale;
    case 'ru':
      return ru_locale;
    case 'uk':
      return uk_locale;
    case 'zh':
      return zh_locale;
    default:
      return en_locale;
  }
};

export default function getLink(value: string) {
  return encodeURIComponent(
    value
      .toLowerCase()
      // Replace all whitespace with dashes
      .replace(/\s/g, '-'),
  );
}

export function getDLCName(tag: string) {
  if (tag.startsWith('x2')) {
    return 'Ragnarok';
  }

  if (tag.startsWith('x3')) {
    return 'Atlantis';
  }

  if (tag.startsWith('x4')) {
    return 'Eternal Embers';
  }

  return '';
}

export function getDLCAbbreviation(tag: string) {
  if (tag.startsWith('x2')) {
    return 'R';
  }

  if (tag.startsWith('x3')) {
    return 'A';
  }

  if (tag.startsWith('x4')) {
    return 'E';
  }

  return '';
}

export function getUniqueSuffixValue(value: string, fallback: string) {
  const parts = value.split('-');
  const suffixNumber = parseInt(parts[parts.length - 1]);
  return isNaN(suffixNumber) ? `${value}-1` : `${fallback}-${suffixNumber + 1}`;
}

export function typedKeys<T>(obj: T) {
  return Object.keys(obj) as Array<keyof T>;
}
